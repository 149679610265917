<template>
  <a-select
   :mode="field.mode == 'default' ? null : field.mode"
    v-model:value="modalRef.selectedRows"
    :show-search="field.show_search == undefined ? true : field.show_search"
    :disabled="field.disabled"
    :placeholder="
      field.placeholder_i18n == undefined ? field.placeholder : t(field.placeholder_i18n)
    "
    :default-value="field.defaultValue"
    :dropdown-match-select-width="500"
    :maxTagCount="field.maxTagCount"
    :maxTagTextTength="field.maxTagTextLength"
    @change="handleChange"
    size="small"
    :allow-clear="field.allowClear"
    :filter-option="filterOption"
    :style="select_style"
  >
    <a-select-option
      v-for="option in datasource"
      :key="field.rowKey ? field.rowKey : option.value"
      :value="option.value"
    >
      {{ option.label }}
    </a-select-option>
  </a-select>
  <a-button
    v-if="field.cb"
    size="small"
    @click="btnClick(field.cb)"
    :style="button_style"
    :title="'点击新建' + field.label"
  >
    <template #icon>
      <plus-outlined theme="filled" />
    </template>
  </a-button>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, reactive, watch, watchEffect } from 'vue';
import { RequestData } from '@/utils/hooks/useFetchData';
import getFormConfig from '@/components/FormRender/RemoteSelect/index';
import { PlusOutlined } from '@ant-design/icons-vue';
import bus from '@/utils/bus';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'RemoteSelect',
  props: {
    field: {
      type: Object,
      required: true,
    },
    valueKey: {
      type: String,
    },
    labelKey: {
      type: String,
    },
    labelSpace: {
      type: String,
      default: '-',
    },
    labelKeyList: {
      type: Array,
    },
    value: {
      type: Array,
      Number,
      required: true,
    },
    formModel: {
      type: Object,
      required: true,
    },
    init_model: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['update:value'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const event_obj = bus;
    const modalRef = reactive({
      selectedRows: new Array<number | any>(),
      datasource: new Array<any>(),
    });
    const { searchConfig, getData } = getFormConfig(props.field.modalType);
    const search_modelRef = reactive({
      ...searchConfig.model,
      ...props.init_model,
    });
    const old_obj: { [key: string]: any } = {};
    const button_style = computed(() => {
      return props.field.cb ? 'width: 13%;min-width:17px;' : 'width: 100%;min-width:30px;';
    });
    const select_style = computed(() => {
      return props.field.cb ? 'width: 84%;min-width:100px;' : 'width: 100%;min-width:100px;';
    });
    const datasource = computed(() => {
      return modalRef.datasource.map(item => {
        if (props.labelKeyList) {
          let label = [];
          for (let key of props.labelKeyList) {
            if (item[key as string] != null) {
              label.push(item[key as string]);
            }
          }
          return {
            value: item[props.valueKey ? (props.valueKey as string) : 'id'],
            label: label.join(props.labelSpace),
          };
        } else {
          return {
            value: item[props.valueKey ? (props.valueKey as string) : 'id'],
            label: item[props.labelKey ? (props.labelKey as string) : 'name'],
          };
        }
      });
    });
    const getDefaultValue = (list: any) => {
      if (props.field.default && props.field.mode == 'default' && list.length > 0) {
        props.formModel[props.field.name] =
          list[0][props.valueKey ? (props.valueKey as string) : 'id'];
      }
    };
    const check_params_need_request = () => {
      let is_ok = true;
      if (searchConfig.watch) {
        for (let k of searchConfig.watch) {
          if (props.formModel) {
            if (props.formModel[k] === null || props.formModel[k] === undefined) {
              is_ok = false;
            }
          }
        }
      }
      return is_ok;
    };
    watch(
      () => props.formModel[props.field.name],
      () => {
        if (props.field.mode === 'default') {
          if (props.formModel[props.field.name]) {
            modalRef.selectedRows = [props.formModel[props.field.name]];
          }
        } else {
          //modalRef.selectedRows.length = 0;
          if (props.formModel[props.field.name] && props.formModel[props.field.name].length > 0) {
            modalRef.selectedRows = props.formModel[props.field.name].map((item: any) => {
              return Number(item);
            });
          }
        }
      },
      { deep: true },
    );
    onMounted(() => {
      if (searchConfig.watch) {
        for (let k of searchConfig.watch) {
          if (props.formModel) {
            old_obj[k] = props.formModel[k];
          }
        }
      }
      if (searchConfig.watch && props.formModel) {
        for (let k of searchConfig.watch) {
          search_modelRef[k] = props.formModel[k];
          watch(
            () => props.formModel[k],
            () => {
              let change = false;
              if (searchConfig.watch && props.formModel) {
                for (let k of searchConfig.watch) {
                  if (old_obj[k] != props.formModel[k]) {
                    change = true;
                    search_modelRef[k] = props.formModel[k];
                  }
                }
              }
              if (change && check_params_need_request()) {
                getData({ ...search_modelRef }).then(function (res: RequestData<any>) {
                  modalRef.datasource = res.data;
                  getDefaultValue(res.data);
                });
              }
            },
            { immediate: true },
          );
        }
      }
      getData({ ...search_modelRef }).then(function (res: RequestData<any>) {
        modalRef.datasource = res.data;
      });
    });

    const get_value = (): void => {
      if (props.field.mode === 'default') {
        modalRef.selectedRows = [];
        if (props.value) {
          modalRef.selectedRows.push(props.value);
        }
      } else {
        if (props.value && props.value.length > 0) {
          modalRef.selectedRows = [];
          props.value.map((item: any) => {
            modalRef.selectedRows.push(Number(item));
          });
        }
      }
    };
    watchEffect(get_value);
    // watch(
    //   props.value,
    //   () => {
    //     if (props.field.mode === 'default') {
    //       if (props.value) {
    //         modalRef.selectedRows.length = 0;
    //         modalRef.selectedRows.push(props.value);
    //       }
    //     } else {
    //       if (props.value && props.value.length > 0) {
    //         modalRef.selectedRows.length = 0;
    //         props.value.map((item: any) => {
    //           modalRef.selectedRows.push(Number(item));
    //         });
    //       }
    //     }
    //   }
    // )

    event_obj.on('remoteselect', () => {
      getData({ ...search_modelRef }).then(function (res: RequestData<any>) {
        modalRef.datasource = res.data;
      });
    });
    const btnClick = (cb: any) => {
      cb().then(function (value: any) {
        getData({ ...search_modelRef }).then(function (res: RequestData<any>) {
          modalRef.datasource = res.data;
          if (value?.id) {
            // 暂时 用于家庭地址、工作地址的表单效果
            modalRef.datasource.push(value);
            if (props.field.mode === 'default') {
              modalRef.selectedRows = [Number(value.id)];
            } else {
              if (modalRef.selectedRows && modalRef.selectedRows.length > 0) {
                modalRef.selectedRows.push(Number(value.id));
              } else {
                modalRef.selectedRows = [Number(value.id)];
              }
            }
            if (props.field.mode == 'default') {
              if (modalRef.selectedRows.length == 1) {
                emit('update:value', modalRef.selectedRows[0]);
              } else {
                emit('update:value', null);
              }
            } else {
              if (modalRef.selectedRows.length > 1) {
                emit('update:value', modalRef.selectedRows);
              } else {
                emit('update:value', modalRef.selectedRows);
              }
            }
          } else {
            if (props.field.mode === 'default') {
              modalRef.selectedRows = [Number(value)];
            } else {
              if (modalRef.selectedRows && modalRef.selectedRows.length > 0) {
                modalRef.selectedRows.push(Number(value));
              } else {
                modalRef.selectedRows = [Number(value)];
              }
            }
            if (props.field.mode == 'default') {
              if (modalRef.selectedRows.length == 1) {
                emit('update:value', modalRef.selectedRows[0]);
              } else {
                emit('update:value', null);
              }
            } else {
              if (modalRef.selectedRows.length > 1) {
                emit('update:value', modalRef.selectedRows);
              } else {
                emit('update:value', modalRef.selectedRows);
              }
            }
          }
        });
      });
    };

    const handleChange = () => {
      if (props.field.mode == 'default') {
        emit('update:value', modalRef.selectedRows);
      } else {
        if (modalRef.selectedRows.length > 1) {
          emit('update:value', modalRef.selectedRows);
        } else {
          emit('update:value', modalRef.selectedRows);
        }
      }
    };
    const filterOption = (val: string, option: any) => {
      return option.props.children[0].children.toLowerCase().indexOf(val.toLowerCase()) >= 0;
    };

    return {
      t,
      props,
      modalRef,
      search_modelRef,
      searchConfig,
      handleChange,
      filterOption,
      datasource,
      select_style,
      button_style,
      btnClick,
    };
  },

  data() {
    return {
      model: this.value,
    };
  },
  components: {
    PlusOutlined,
  },
});
</script>
